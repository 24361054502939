import React from 'react'
import renderBloks from '@renderBloks'
import { Box, makeStyles } from '@material-ui/core'
import { Text } from '@system'

const useStyles = makeStyles(theme => ({
  headline: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    paddingBottom: '10px',
    display: 'block',
  },
}))

const SolutionsMenuList = props => {
  const classes = useStyles()
  return (
    <Box pb="30px">
      {!!props.blok.headingLink && (
        <Text className={classes.headline}>
          {renderBloks(props.blok.headingLink)}
        </Text>
      )}
      <Box display="flex" flexDirection="column">
        {renderBloks(props.blok.links)}
      </Box>
    </Box>
  )
}

export default SolutionsMenuList
